import React from 'react'

const AnasayfaIntro = () => {
  return (
    <div>
    <h2 className='text-center'>2014 yılından bu yana </h2>
    </div>
  ) 
}

export default AnasayfaIntro