import CustomRoutes from "./router/custom-routes";


function App() {
  return (
    <>
          <CustomRoutes/>
    </>
  );
}

export default App;
