import React from "react";
import "./kapatilan-text.css";
import { Col, Row } from "react-bootstrap";

const TarihselAciklama = () => {
  return (
    <>
      <Row>
        <Col lg={12}>
          <h2 style={{ fontWeight: "bold" }}>2013’den Bügüne</h2>
          <p className="small-paragraph">
            Gülen hareketi ile ilişkilendirilen kurumların kapatılma ve el
            konulma süreçleri, 2013 yılında ortaya çıkan yolsuzluk operasyonları
            sonrasında Türk hükümeti’nin, Gülen Hareketi'ni sorumlu tutması ve
            harekete yönelik baskıları arttırması ile başlamıştır. 15 Temmuz
            2016'daki başarısız darbe girişimi, Türk hükümeti için bir dönüm
            noktası olmuş, bu hadiseden beş gün sonra, hiçbir çatışmanın
            kalmadığı ve ihtiyaç olmayan bir zeminde olağanüstü hal ilan
            edilmiştir. Hükümet, darbe girişimini Gülen Hareketi'ne bağlamış ve
            bu olayı fırsat bilerek geniş çaplı bir “kıyım” operasyonu
            başlatmıştır. Bu operasyon, Türkiye'nin çeşitli sektörlerinde
            faaliyet gösteren binlerce kurumu etkilemiştir.
          </p>
          <p className="small-paragraph">
            Operasyonun bir sonucu olarak, Gülen Hareketi'ne ait olduğu iddia
            edilen medya kuruluşları, eğitim kurumları, vakıflar, sağlık
            tesisleri ve diğer birçok kurum kapatılmış veya el konulmuştur.
            Kapatılan kurumlar arasında özel eğitim kurumları, üniversiteler,
            medya kuruluşları ve vakıf işletmeleri bulunmaktadır. Bu süreçte,
            kurumların yöneticileri ve çalışanları tutuklanmış, mal varlıklarına
            el konulmuş ve işleri sonlandırılmıştır. Kapatılan özel öğretim
            kuruluşlarında çalışan 22 bin 474 yönetici, eğitimci, öğretmen,
            uzman öğretici, usta öğretici ve diğer personelin çalışma izni
            onayları iptal edilmiş ve bu personele başka bir özel öğretim
            kurumunda çalışma izin onayı düzenlenmemesi yönünde uygulamalar
            yapılmıştır.
          </p>
          <p className="small-paragraph">
            2014 yılında 6545 sayılı Kanunla sulh ceza hakimlikleri oluşturulmuş
            ve bu hakimlikler hükumet tarafından önce “paralel yapı” daha sonra
            ise “silahlı terör örgütü” olarak tanımlanan Gülen Hareketi ile ilişkili
            olduğu düşünülen kurum, kuruluş ve şirketlere kayyım atayarak
            yönetimlerini ve tüm tasarruf haklarını gerçek sahiplerinden
            almışlardır. Burada öncelikle medya ve basın-yayın kuruluşları
            (başta Bugün ve Zaman Gazeteleri, Samanyolu TV vs.), daha sonra
            eğitim ve öğretim kurumları hedef alınmıştır.
          </p>
        </Col>
      </Row>
    </>
  );
};

export default TarihselAciklama;
