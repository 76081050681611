import React from 'react';
import './spacer.css'; // CSS dosyasını import edin

const Spacer = () => {
  return (
    <div className="spacer"></div>
  );
} 

export default Spacer;
